<section class="container">

    <ng-container *ngIf="emptyList">
        <div class="row justify-content-center my-5">
            <div class="col-8 text-center my-5 py-5">
                <h2 class="mb-1">{{'topNavbar.contentTypes' | translate}}</h2>
                <p class="lead pb-3" style="font-family:'MyFont-Light';">{{'empty_templates' | translate}}</p>
                <div [matTooltip]="!contentTypeUserRights_create ? ('home.tooltip' | translate) : null">
                    <button mat-button 
                    class="orange-button" 
                    id="btn-create" 
                    (click)="openDialogNewContentType();" 
                    [disabled]="!contentTypeUserRights_create"
                    >
                        <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
                    </button>
                </div>
            </div>
          </div>
    </ng-container>

    <ng-container *ngIf="!emptyList">
        <div class="row mt-4">

            <div class="mt-3">
                <h1 class="mb-1">{{'topNavbar.contentTypes' | translate}}</h1>
                <p style="font-family:'MyFont-Light';">{{'headlines.subDPP' | translate}}</p>
            </div>

            <div class="col-12">
                <section class="mt-1 filter-section d-flex flex-row flex-wrap  align-items-center" >    
                    <div [matTooltip]="!contentTypeUserRights_create ? ('home.tooltip' | translate) : null" class="d-flex flex-row flex-wrap">
                        <button mat-button
                                class="orange-button"
                                id="btn-create" 
                                (click)="openDialogNewContentType();" 
                                [disabled]="!contentTypeUserRights_create"
                        >
                            <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
                        </button>

                        <div class="ps-3">
                            <button mat-button
                                    class="orange-button"
                                    id="btn-import"
                                    (click)="fileInput.click()">
                                <mat-icon>publish</mat-icon> {{'pass.import' | translate}}
                            </button>
                            <input type="file" 
                                   id="myfile-pick-title" 
                                   name="myfile-pick-title" 
                                   style="display: none;" 
                                   accept=".zip, .nvpt" 
                                   (change)="readURL($event);" 
                                   #fileInput>
                        </div>
                    </div>
                
                    <mat-form-field class="pt-3 mb-0 mt-0 white-input-bg">
                        <mat-label>{{'search_title' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>
                </section>
                
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 mb-4">
                <div class="mat-elevation-z8 rounded shadow-sm" id="table-container">
                    <table mat-table [dataSource]="listdata" matSort> 
            
                        <ng-container matColumnDef="coverImage">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'coverImages.titleSingle' | translate}} </th>
                            <td mat-cell *matCellDef="let row" (click)="openDialogCoverImage(row);" style="width: 12%;">
                                <div  class="edit-image">
                                    <div class="edit-icon"><mat-icon>edit</mat-icon></div>
                                    <img width="70" height="50px" id="coverImage" src="{{row.coverImage}}" onerror="this.src='./../../assets/img/default.jpg'">
                                </div>
                            </td>
                        </ng-container>

                        <!-- displayName Column -->
                        <ng-container matColumnDef="displayName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'contentTypes.name' | translate}} </th>
                            <td mat-cell *matCellDef="let row"  (click)=setRouteLink(row);> 
                                <span *ngIf="row.displayName" class="text-wrap text-break">{{row.displayName}}</span> 
                            </td>
                        </ng-container>

                        <!-- comment Column -->
                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'contentTypes.comment' | translate}} </th>
                            <td mat-cell *matCellDef="let row" (click)=setRouteLink(row);> 
                                <span *ngIf="row" class="text-wrap text-break">{{showCommentsOnList(row)}}</span> 
                            </td>
                        </ng-container>
                    
                        <!-- cretionDate Column -->
                        <ng-container matColumnDef="creationDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
                            <td mat-cell *matCellDef="let row"  (click)=setRouteLink(row);>
                                <span *ngIf="row.creationDate" class="text-wrap text-break">
                                    {{row.creationDate | date: 'dd.MM.yyyy, HH:mm'}}
                                </span>         
                            </td>
                        </ng-container>
                    
                        <!-- timeStamp Column -->
                        <ng-container matColumnDef="timeStamp">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
                            <td mat-cell *matCellDef="let row"  (click)=setRouteLink(row);> 
                                <span *ngIf="row.timeStamp" class="text-wrap text-break">
                                    {{ row.timeStamp | date: 'dd.MM.yyyy, HH:mm' }}
                                </span>
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="deleteContentType">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row"> 
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="openDialogCoverImage(row);" [disabled]="!contentTypeUserRights_create">
                                        <mat-icon class="material-symbols-outlined">image</mat-icon> {{'openCoverImage' | translate}}
                                    </button>
                                    <button mat-menu-item (click)="openContentTypeView(row);" [disabled]="!contentTypeUserRights_create">
                                        <mat-icon class="material-symbols-outlined">settings</mat-icon> {{'openContentType' | translate}}
                                    </button>
                                    <button mat-menu-item (click)="openDuplicatePassDialog(row);" [disabled]="!contentTypeUserRights_create">
                                        <!-- [disabled]="!contentTypeUserRights_create" -->
                                        <mat-icon>content_copy</mat-icon> {{'duplicate' | translate}}
                                    </button>
                                    <button mat-menu-item (click)="exportPassTamplate(row);" [disabled]="!contentTypeUserRights_create">
                                        <mat-icon class="material-symbols-outlined">download</mat-icon> {{'export' | translate}}
                                    </button>
                                    <button mat-menu-item (click)="activeDelete(row);" [disabled]="!contentTypeUserRights_delete">
                                        <mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{'delete' | translate}}
                                    </button>
                                </mat-menu>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;" class="p-1"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break"></tr>
                    
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">{{'no_matching_data' | translate}} "{{input.value}}"</td>
                        </tr>
                    </table>
                
                    <mat-paginator [pageSizeOptions]="paginatorSize" aria-label="Select page of users" showFirstLastButtons class="pb-2"></mat-paginator>
                </div>
            </div>
        </div>
    </ng-container>


</section>