import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { SettingsService } from '../service/settings.service';
import { TaskTemplateFieldNewOptionDialogComponent } from '../task-template-field-new-option-dialog/task-template-field-new-option-dialog.component';
import { deepCopy } from '../../../../backend/utils/object';
import { isDate, isTime } from '../../../../backend/src/filter';
import { TaskTemplateImportFieldEditDialogComponent } from '../task-template-import-field-edit-dialog/task-template-import-field-edit-dialog.component';

@Component({
  selector: 'app-task-template-field-dialog',
  templateUrl: './task-template-field-dialog.component.html',
  styleUrls: ['./task-template-field-dialog.component.css']
})
export class TaskTemplateFieldDialogComponent implements OnInit{

  fieldForm!:FormGroup
  newItemForm!:FormGroup
  fieldData:any
  tasktemplateUserRights_update:any
  allContentTypes:any
  defaultDate:any
  sourceForm!:FormGroup
  contentTypesList:any=[]
  panelOpenState = false
  editImportedField = false
  editImportedFieldData:any = {}
  editImportedFieldIndex:any

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateFieldDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private settingsService:SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private settingService : SettingsService

  ){
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.contentTypesList = this.settingsService.settingsContentTypesWithId
    this.tasktemplateUserRights_update = this.settingsService.userRolesRights$['workTaskTemplates']['U']
    this.fieldData = this.data.values
    //console.log(this.fieldData)

    this.allContentTypes = this.settingService.settingsContentTypesWithId
    this.fieldForm = this.fb.group({
      id:[],
      type:[],
      title:[],
      key:[],
      mandatory:[],
      readOnly:[],
      payloadKey:[],
      identifierKey: [],
      barcodeType:[],
      style:[],
      value:[],
      searchable:[],
      extendable:[],
      defaultValue:[],
      options:[],
      importedFields:[],
      optionsKey:[],
      contentType:[],
      defaultSelection:[],
      sources:[],
      searchIdentifiers:[],
      listSelection:[],
      unit:[]

    })
    const {type, id, title, key, mandatory, readOnly, payloadKey, identifierKey, style, value, barcodeType, searchable,
    extendable, defaultValue, options, importedFields, optionsKey, contentType, defaultSelection, sources,
    searchIdentifiers, listSelection, unit} = this.fieldData

    this.fieldForm.patchValue({
      id: id ? id : undefined,
      type: type ? type : undefined,
      title: title ? title : undefined,
      key: key ? key : undefined,
      mandatory: mandatory ? mandatory : false,
      readOnly: readOnly ? readOnly : false,
      payloadKey: payloadKey ? payloadKey : undefined,
      identifierKey: identifierKey ? identifierKey : undefined,
      barcodeType: barcodeType ? barcodeType: undefined,
      style: style ? style : undefined,
      value: value ? value : undefined,
      searchable : searchable ? searchable:undefined,
      extendable: extendable ? extendable : undefined,
      defaultValue: defaultValue ? defaultValue : undefined,
      importedFields: importedFields ? importedFields : [],
      options: options ? options : [],
      optionsKey: optionsKey ? optionsKey : undefined,
      contentType: contentType ? contentType : undefined,
      defaultSelection : defaultSelection ? defaultSelection : undefined,
      sources: sources ? sources : [],
      searchIdentifiers:searchIdentifiers ? searchIdentifiers : undefined,
      listSelection:listSelection ? listSelection : undefined,
      unit: unit ? unit : undefined,
    })

    if(type=='showText' || type == 'showImage'){
      if(mandatory){
        this.fieldForm.patchValue({mandatory:false})
      }
    }

    this.newItemForm = this.fb.group({
      id: [],
      key:[],
      tridyPayloadKey:[]
    })

    this.sourceForm = this.fb.group({
      camera:[],
      library:[],
      documents:[],
      scan:[]
    })

    let library=false, documents=false, scan=false, camera=false
    this.fieldForm.value.sources.forEach( value => {
      if(value=="documents"){
        documents=true
      }
      if(value=="camera"){
        camera=true
      }

      if(value=="library"){
        library=true
      }
      
      if(value=="scan"){
        scan=true
      }
    })
   

    this.sourceForm.patchValue({
      camera:camera ? camera : undefined,
      library:library ? library : undefined,
      documents:documents ? documents : undefined,
      scan:scan ? scan : undefined
    })


    if(this.fieldData.type == "dateTime"){
      if( this.fieldData.defaultValue){
        if(this.fieldData.style){
          if(this.fieldData.style == 'time'){
            this.defaultDate = isTime(this.fieldData.defaultValue)
            this.fieldForm.patchValue({defaultValue: this.defaultDate})
          }

          if(this.fieldData.style == 'date'){
            if(this.defaultDate!= 'Invalid Date')
            this.fieldForm.patchValue({defaultValue: this.fieldData.defaultValue})

            if(this.fieldData.defaultValue != 'Invalid Date')
            this.fieldForm.patchValue({defaultValue: new Date(this.fieldData.defaultValue)})

            this.defaultDate = this.isDate(this.fieldData.defaultValue)
            if(this.defaultDate!= 'Invalid Date'){
              this.fieldForm.patchValue({defaultValue: this.defaultDate})
            }

            if(this.defaultDate.includes('.')){
              const values = this.defaultDate.split('.')
              const month =  values[1] -1
              const year =  values[2]
              const day =  values[0]

              this.defaultDate = new Date(year, month, day)
              if(this.defaultDate != 'Invalid Date')
              this.fieldForm.patchValue({defaultValue: this.defaultDate})
            }
          }

          if(this.fieldData.style == 'dateTime'){
            const date = isDate(this.fieldData.defaultValue)
            const time = isTime(this.fieldData.defaultValue)
            const infoDate = date.split('.')
            if(infoDate[0] && infoDate[1] && infoDate[2]){
              let newdate = infoDate[2]+'-'+infoDate[1]+'-'+infoDate[0]+ 'T' + time
              this.defaultDate = new Date(this.defaultDate)
              this.fieldForm.patchValue({defaultValue: newdate})
            }
          }
        }
      }
    }
    this.fieldForm.get("title").valueChanges.subscribe( newtitlevalue =>{
        this.fieldForm.get("key").setValue( newtitlevalue)
    })

    const values = ["library", "camera", "scan","documents"]
    values.forEach( sourceType => {
      this.sourceForm.get(sourceType).valueChanges.subscribe( sourceTypeValue =>{
        if(sourceTypeValue){
          if(this.fieldForm.value.sources){
            
            if(!this.fieldForm.value.sources.includes(sourceType)){
              const sources = this.fieldForm.value.sources
              sources.push(sourceType)
              this.fieldForm.get("sources").setValue( sources)
            }
          }
          
        }else{
          if(this.fieldForm.value.sources.includes(sourceType)){
            let sources = []
            this.fieldForm.value.sources.forEach(source => {
              if(source!=sourceType){
                sources.push(source)
              }
            })
            this.fieldForm.get("sources").setValue( sources)
          }
        }
      })
    })

    this.fieldForm.get("key").valueChanges.subscribe( newkey =>{
      if(newkey.includes(" ")){
        newkey = newkey.replace(" ", "_")
        this.fieldForm.get("key").setValue( newkey)
      }
    })

    this.fieldForm.get("payloadKey").valueChanges.subscribe( newkey =>{
      if(newkey.includes(" ")){
        newkey = newkey.replace(" ", "_")
        this.fieldForm.get("payloadKey").setValue( newkey)
      }
    })

  }


  addItem(){
    const newItem = {
      key: this.newItemForm.value.key,
      tridyPayloadKey: this.newItemForm.value.tridyPayloadKey,
      id: this.makeBtnId(6)
    }

    const allItems = deepCopy(this.fieldForm.value.importedFields)
    allItems.push(newItem)
    this.fieldForm.patchValue({importedFields: allItems})

    this.newItemForm.patchValue({
      key:undefined,
      tridyPayloadKey:undefined
    })

  }

  makeBtnId(length) {
    let result = '';
    const characters = '--ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  deleteItem(item, index){
    const allItems = deepCopy(this.fieldForm.value.importedFields)
    allItems.splice(index,1)
    this.fieldForm.patchValue({importedFields: allItems})
  }

    openEditDialog(importedField: any, index: number): void {
      const dialogRef = this.dialog.open(TaskTemplateImportFieldEditDialogComponent, {
        width: '400px',
        data: { ...importedField }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateItem(result, index);
        }
      });
    }
  
    updateItem(updatedField: any, index: number): void {
      const allItems = [...this.fieldForm.value.importedFields];
      allItems[index] = updatedField;
      this.fieldForm.patchValue({ importedFields: allItems });
    }

  copyKeyValue(){
    const value = this.fieldForm.value.key
    this.fieldForm.patchValue({payloadKey: value})
  }

  onCancel(){
    this.dialogRef.close([this.fieldData, false])
  }

  onSave() {
    if (this.fieldForm.value.type === 'dateTime') {
      if (this.fieldForm.value.defaultValue) {
        if (this.fieldForm.value.defaultSelection === '') {
          this.fieldForm.patchValue({ defaultValue: undefined });
        } else if (!this.fieldForm.value.defaultValue.nanoseconds) {
          if(this.fieldForm.value.style === 'time'){
            const date = new Date()
            const time = this.fieldForm.value.defaultValue.split(':')
            date.setHours(time[0], time[1]);
            this.fieldForm.patchValue({ defaultValue: date });
          }

          if(this.fieldForm.value.style === 'date'){
            const date = new Date(this.fieldForm.value.defaultValue)
            this.fieldForm.patchValue({ defaultValue: date });
          }

          if(this.fieldForm.value.style === 'dateTime'){
            const date = new Date(this.fieldForm.value.defaultValue)
            this.fieldForm.patchValue({ defaultValue: date });
          }
        }
      }
    }

    // TODO: metaData is not Boolean, but is stored as boolean! So I remove it until it's fixed (CHB; 29.11.2023)
    delete this.fieldForm.value["metaData"]

    if(this.fieldForm.value.type=='tridy'){
      if(!this.fieldForm.value.searchIdentifiers){
        this.fieldForm.patchValue({ searchIdentifiers: false });
      }

      if(!this.fieldForm.value.listSelection){
        this.fieldForm.patchValue({ listSelection: false });
      }

      if(this.newItemForm.value.key && this.newItemForm.value.tridyPayloadKey){
        if(this.fieldForm.value.importedFields){
          const exists = this.fieldForm.value.importedFields.find( imported => imported.key == this.newItemForm.value.key && this.newItemForm.value.tridyPayloadKey )
          if(!exists)
            this.addItem()
        }
      }
    }

    const newValues = removeUndefinedValuesFromObject(this.fieldForm.value)

    if (newValues.type != 'document') {
      delete this.fieldForm.value["sources"]
    }

    if (newValues.type != 'options') {
      delete this.fieldForm.value["options"]
    }

    this.dialogRef.close([this.fieldForm.value,true])
  }

  addNewOptionToField(){
    let dialogRef = this.dialog.open(TaskTemplateFieldNewOptionDialogComponent, {
      data: { 
         values: ''
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        let options = this.fieldForm.value.options
        options.push(result[0])
        this.fieldForm.patchValue({...options})
      }
    });
  }

  editOptionToField(optionSelected, indexSelected){
    let dialogRef = this.dialog.open(TaskTemplateFieldNewOptionDialogComponent, {
      data: { 
         values: optionSelected,
         index: indexSelected
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        let options =[]
        this.fieldForm.value.options.forEach( (option, index)=> {
          if(index==indexSelected){
            options.push(result[0])
          }else{
            options.push(option)
          }
        })
        this.fieldForm.patchValue({options: options})

        if(this.fieldForm.value.defaultValue==optionSelected){
          this.fieldForm.patchValue({defaultValue: result[0]})

        }
      }
    });
  }

  deleteOption(optionSelected, indexSelected){
    let options = []
    this.fieldForm.value.options.forEach((option, index) => {
      if(index!=indexSelected)
      options.push(option)
    });

    if(this.fieldForm.value.defaultValue==optionSelected){
      this.fieldForm.patchValue({defaultValue: undefined})
    }

    this.fieldForm.patchValue({options: options})
  }


  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  formatTime(date){
    var dateToinput=""
    const d = new Date(date)

    const hours = d.getHours()
    const minutes = d.getMinutes()
    const m = d.toTimeString()
    let h
    let min

    if(hours<10){
      h = '0' + hours
    }else{
      h = hours 
    }

    if(minutes<10){
      min = '0' + minutes
    }else{
      min =  minutes
    }
 
    dateToinput = h + ":" + min

    return dateToinput
  }


  isDate(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString("de-DE",{year: 'numeric', month: '2-digit', day:'2-digit'})
        // dateValue = date.toLocaleDateString("en-GB",{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString("de-DE",{year: 'numeric', month: '2-digit', day:'2-digit'})
        // dateValue = date.toLocaleDateString("en-GB",{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate
            dateValue = newdate.toLocaleDateString("de-DE",{year: 'numeric', month: '2-digit', day:'2-digit'})
            // dateValue = newdate.toLocaleDateString("en-GB",{year: 'numeric', month: '2-digit', day:'2-digit'})
      }catch(errro){}
    }
   return dateValue
  }
}
